import { useMediaQuery } from "@mui/material";
import { useClickAway } from "@uidotdev/usehooks";
import { Fragment, forwardRef, useState } from "react";
import Color from "../../../assets/colors";
import {
  IconContainer,
  LinkStyled,
  SGCountryFlag,
  TextStyled,
  TooltipStyled,
} from "./styles";

export function SharedSGTooltip(props) {
  const { PopperProps, placement } = props;

  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  function handleCloseSGTooltip() {
    setIsTooltipOpen(false);
  }

  function handleOpenSGTooltip(event) {
    event.stopPropagation();
    event.preventDefault();
    setIsTooltipOpen(true);
  }

  const ref = useClickAway(() => {
    handleCloseSGTooltip();
  });

  return isDesktop ? (
    <SharedSGJobCardTooltip
      placement={placement ?? "bottom"}
      PopperProps={PopperProps}
    >
      <IconContainer>
        <SGCountryFlag />
      </IconContainer>
    </SharedSGJobCardTooltip>
  ) : (
    <SharedSGJobCardTooltip
      ref={ref}
      placement={placement ?? "bottom"}
      PopperProps={PopperProps}
      open={isTooltipOpen}
    >
      {/* NOTE: Unable to use SharedSGCountryImage */}
      <IconContainer>
        <SGCountryFlag onClick={handleOpenSGTooltip} />
      </IconContainer>
    </SharedSGJobCardTooltip>
  );
}

// NOTE: ForwardRef needed for ClickAwayListener Parent to work
const SharedSGJobCardTooltip = forwardRef(function SharedSGJobCardTooltip(
  props,
  ref
) {
  const { placement } = props;
  const isTablet = useMediaQuery("(min-width: 640px)");

  return (
    <TooltipStyled
      placement={placement ?? "bottom"}
      ref={ref}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: Color.white,
            color: Color.black,

            borderRadius: "8px",
            border: `2.375px solid ${Color.black}`,

            width: "263px",
            height: isTablet ? "81px" : "34px",
            padding: isTablet ? "18.5px 16px" : "0",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      }}
      title={<SharedSGJobCardTooltipContent />}
      {...props}
    >
      {props.children}
    </TooltipStyled>
  );
});

function SharedSGJobCardTooltipContent() {
  return (
    <Fragment>
      <TextStyled>
        Visit{" "}
        <LinkStyled href="https://sg.hiredly.com" target="_blank">
          sg.hiredly.com
        </LinkStyled>{" "}
        for more Singaporean jobs!
      </TextStyled>
    </Fragment>
  );
}
