import useMediaQuery from "@mui/material/useMediaQuery";
import Cookies from "js-cookie";
import moment from "moment";
import { usePostHog } from "posthog-js/react";
import { useCallback, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import { assetDomain } from "../../../config/config";
import { formatSalary } from "../../../helpers/conditional_rendering";
import {
  getCompanyLogoImage,
  getGaSessionID,
} from "../../../helpers/data_management";
import { debounce } from "../../../helpers/debounce";
import {
  getJobDiscovery,
  posthogHideJob,
  posthogJobClick,
} from "../../../helpers/posthog_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  postGaSessionId,
  undoHideJob,
  updateFeedbackHideJob,
  updateHideJob,
  updateHoverJob,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignUpOrigin,
} from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/job_type";
import { SharedSGTooltip } from "../../shared/SharedSGJobTooltip/SharedSGJobTooltip";
import {
  ArrowIconStyled,
  BookmarkButton,
  BottomContainer,
  BoxContainer,
  CircularProgressStyled,
  CloseIcon,
  CompanyLogoStyled,
  CompanyNameStyled,
  CustomTooltip,
  DividerStyled,
  DollarIcon,
  GlobalChip,
  GlobalIcon,
  Greenthang,
  HideButton,
  HideIcon,
  HideJobContainer,
  HighlightChip,
  HighlightIcon,
  InnerWrapperContainer,
  JobCardContainer,
  JobTitleStyled,
  JobTypeChip,
  KeywordChip,
  LeftArrowIcon,
  LinkContainer,
  LocationIcon,
  MobileApplyButton,
  MobileChipContainer,
  MobileDetailChip,
  MobileKeywordContainer,
  OptionContainer,
  OptionLabel,
  PostedTextStyled,
  RadioStyled,
  SaveFilledIcon,
  SaveOutlineIcon,
  SidebarColor,
  SpotlightChip,
  SpotlightIcon,
  TextButton,
  TextStyled,
  TopContainer,
} from "./styles";

let isSavingJob = false;

function AccordionJobCard(props) {
  const {
    job,
    index,
    onFollowJobClicked,
    onApplyJobClicked,
    unSaveJob,
    currentId,
    origin,
  } = props;

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const dispatch = useDispatch();

  const reduxUser = useSelector((state) => state?.user?.user);
  const jobListTab = useSelector((state) => state.jobs.jobListTab);
  const fypTab = Number(useSelector((state) => state.jobs.fypTab));
  const viewHistoryState = useSelector((state) => state.jobs.viewHistoryState);
  const selectedJob = useSelector((state) => state?.jobs?.job?.id);
  const searchTerm = useSelector(
    (state) => state?.jobs?.jobListFilter?.keyword
  );

  const [hideJob, setHideJob] = useState(false);
  const [confirmHideJob, setConfirmHideJob] = useState(false);
  const [hoverApplyButton, setHoverApplyButton] = useState(false);
  const [applyingJob, setApplyingJob] = useState(false);
  const [cardHover, setCardHover] = useState(false);

  const jobDiscovery = getJobDiscovery(jobListTab, viewHistoryState, fypTab);

  const company =
    job?.crossedBorder &&
    job?.crossBorderData?.source_country !=
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()
      ? job?.crossBorderData?.company
      : job?.company;

  const companyLogoUrl =
    typeof company?.logo !== "string"
      ? company?.logo !== null
        ? company?.logo?.url
        : null
      : company?.logo;

  const companyLogo = company?.logo
    ? getCompanyLogoImage(companyLogoUrl)
    : assetDomain +
      "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png";

  const chipExist =
    job?.spotlight ||
    job?.boosted ||
    job?.jobSlotType === "job_slot_max" ||
    job?.jobSlotType === "job_slot_plus" ||
    job?.globalHirePreferences?.rightToWorkOpenToSponsor;

  const boosted =
    job?.spotlight ||
    job?.boosted ||
    job?.jobSlotType === "job_slot_max" ||
    job?.jobSlotType === "job_slot_plus";

  const jobSlotUpgrade =
    job?.jobSlotType === "job_slot_max" || job?.jobSlotType === "job_slot_plus";

  const posthog = usePostHog();

  function getTime(job) {
    let activeAtDate = job?.activeAt || job?.active_at;
    let jobActiveAt = moment(activeAtDate, "YYYYMMDD");
    let timeNow = moment(moment(), "YYYYMMDD");

    const time =
      timeNow.diff(jobActiveAt, "months") > 3
        ? null
        : timeNow.diff(jobActiveAt, "months") > 1
        ? timeNow.diff(jobActiveAt, "months") + " months ago"
        : moment(activeAtDate).fromNow();

    return time;
  }

  function handleTriggerGaSessionId(job) {
    const gaSessionId = getGaSessionID();

    if (gaSessionId) {
      dispatch(
        postGaSessionId({
          jobId: job?.id,
          sessionId: gaSessionId,
        })
      );
    }
  }

  function trackImpressionInView(job, index) {
    const cleanSalary = job?.salary
      ?.split("-")
      .map((item) => item.replaceAll("RM", ""));

    posthog.capture("job_impression", {
      origin: "job_list",
      index: index,
      job_id: job?.id,
      job_title: job?.title,
      job_type: job?.jobType,
      job_discovery: jobDiscovery,
      spotlight: job?.spotlight,
      featured: job?.boosted,
      keyword: job?.keywordHighlight,
      scraped: job?.scraped,
      category: job?.category,
      job_slot_type: job?.jobSlotType,
      track_titles: job?.tracks?.map((track) => track.title) ?? [],
      search_term: searchTerm ?? "",
      company_id: job?.company.id,
      company_name: job?.company.name,
      location: job?.location,
      state_region: job?.stateRegion,
      hide_salary:
        !job?.salary ||
        job?.salary.toString().toLowerCase().includes("undisclosed")
          ? true
          : false,
      career_level: job?.careerLevel,
      min_years_experience: job?.minYearsExperience,
      max_years_experience: job?.maxYearsExperience,
      min_salary_range: parseInt(cleanSalary[0]) ?? 0,
      max_salary_range: parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
    });

    sendTrackingEvent({
      event: "CE_job-impression",
      "job-id": job?.id,
      origin: "job_list",
      "job-discovery": jobDiscovery,
      search_term: searchTerm ?? "",
    });
  }

  function handleClickLogo(event, companySlug) {
    event.stopPropagation();
    event.preventDefault();

    if (companySlug) {
      window.open(`/companies/${companySlug}`, "_blank");
    }
  }

  const debouncedHoverCard = useCallback(
    debounce((params) => {
      setCardHover(params);
      dispatch(updateHoverJob(job));
    }, 100),
    []
  );

  const handleHoverEnter = useCallback(() => {
    debouncedHoverCard(true);
  }, [debouncedHoverCard]);

  const handleHoverLeave = useCallback(() => {
    debouncedHoverCard.cancel();
    setCardHover(false);
    dispatch(updateHoverJob({}));
  }, [debouncedHoverCard]);

  function clickHandler(event, jobData) {
    event.stopPropagation();
    event.preventDefault();

    handleTriggerGaSessionId(job);

    const companyData = jobData?.crossedBorder
      ? jobData?.crossBorderData?.company
      : company;

    posthogJobClick(jobData, companyData, searchTerm, "job_list", jobDiscovery);

    sendTrackingEvent({
      event: "CE_job-click",
      "job-id": jobData?.id,
      origin: "job_list",
      "job-discovery": jobDiscovery,
      search_term: searchTerm ?? "",
    });

    if (origin === "job_list") {
      Cookies.set("click-source", "job_listing_card");
    }

    const url =
      jobData?.crossedBorder && jobData?.scraped
        ? jobData?.crossBorderData?.source_country === "SG" &&
          process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
          ? jobData?.crossBorderData?.source_country_url +
            `?source=my&origin=${jobDiscovery}`
          : jobData?.crossBorderData?.source_country_url
        : `/jobs/${jobData.slug}?origin=${jobDiscovery}`;

    window.open(url);
  }

  function handleApplyJob(event, job) {
    event.stopPropagation();
    event.preventDefault();

    onApplyJobClicked(job);
  }

  function handleHideJob(event, job) {
    event.stopPropagation();
    event.preventDefault();

    const params = {
      jobId: job?.id,
      rejectionReason: "",
    };

    dispatch(updateHideJob(params)).then((res) => {
      if (res?.type === types.HIDE_JOB_SUCCESS) {
        if (jobDiscovery === "rec_profile") {
          sendTrackingEvent({
            event: "CE_job-hide-click-rec-profile",
            "job-id": job?.id,
          });
        }
        if (jobDiscovery === "rec_activity") {
          sendTrackingEvent({
            event: "CE_job-hide-click-rec-activity",
            "job-id": job?.id,
          });
        }
        posthogHideJob(job, "job_list", jobDiscovery);
        setHideJob(true);
      }
    });
  }

  function handleUndoHideJob(job) {
    const params = {
      jobId: job?.id,
    };

    dispatch(undoHideJob(params)).then((res) => {
      res?.type === types.UNDO_HIDE_JOB_SUCCESS && setHideJob(false);
    });
  }

  function handleConfirmHideJob() {
    setConfirmHideJob(true);
  }

  function handleApplyButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (job?.haveApplied) {
      return;
    }

    setApplyingJob(true);
    onApplyJobClicked(job);
    setApplyingJob(false);
  }

  return origin === "for-you-page" && hideJob ? (
    confirmHideJob ? null : (
      <HideJobCard
        job={job}
        handleUndoHideJob={handleUndoHideJob}
        handleConfirmHideJob={handleConfirmHideJob}
      />
    )
  ) : job !== null ? (
    <LinkContainer
      href={`/jobs/${job?.slug}`}
      onClick={(event) => clickHandler(event, job)}
    >
      {/* OUTER MOST CONTAINER OTHER THAN THE LINK COMPONENT */}
      <JobCardContainer
        $selected={selectedJob === job?.id}
        $spotlight={job?.spotlight || jobSlotUpgrade}
        $highlight={job?.boosted}
        $boosted={boosted}
        cardHover={cardHover}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
      >
        {/* LEFT HIGHLIGHT BANNER */}
        {boosted ? (
          <SidebarColor
            $spotlight={job?.spotlight || jobSlotUpgrade}
            $highlight={job?.boosted}
          />
        ) : null}
        {/* MAIN JOB CARD SECTION */}
        <InnerWrapperContainer
          container
          flexDirection={"row"}
          width={"100%"}
          position={"relative"}
        >
          <CustomTooltip followCursor title={"Click to view full job details"}>
            <InnerWrapperContainer className="responsive">
              <TopContainer $boosted={boosted}>
                {chipExist || (job?.stateRegion === "Singapore" && isMobile) ? (
                  <BoxContainer
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <BoxContainer gap={"0.5rem"}>
                      {isMobile && job?.stateRegion === "Singapore" && (
                        <>
                          <SharedSGTooltip
                            showWhenTap
                            placement={"bottom-start"}
                          />
                          {chipExist ? (
                            <DividerStyled orientation="vertical" />
                          ) : null}
                        </>
                      )}
                      {job?.boosted ? (
                        <HighlightChip>
                          <HighlightIcon />
                        </HighlightChip>
                      ) : null}
                      {job?.spotlight || jobSlotUpgrade ? (
                        <SpotlightChip>
                          <SpotlightIcon />
                        </SpotlightChip>
                      ) : null}
                      {job?.globalHirePreferences?.rightToWorkOpenToSponsor ? (
                        <GlobalChip>
                          <GlobalIcon />
                          Global Hire
                        </GlobalChip>
                      ) : null}
                    </BoxContainer>
                    {job?.category === "aggregated" ? null : (
                      <ButtonGroup
                        job={job}
                        origin={origin}
                        currentId={currentId}
                        onFollowJobClicked={onFollowJobClicked}
                        unSaveJob={unSaveJob}
                        handleHideJob={handleHideJob}
                      />
                    )}
                  </BoxContainer>
                ) : null}
                <BoxContainer
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? "0.5rem" : "0"}
                  position={"relative"}
                >
                  {/* LEFT SIDE - JOB TITLE, SALARY AND LOCATION */}
                  <BoxContainer
                    flexDirection={isMobile ? "row" : "column"}
                    gap={"0.625rem"}
                    width={isMobile ? "100%" : "424px"}
                    minWidth={isMobile ? "100%" : "424px"}
                  >
                    <InView
                      style={{ width: "100%" }}
                      onChange={(inView) =>
                        inView === true && trackImpressionInView(job, index)
                      }
                    >
                      <JobTitleStyled variant={"h2"}>
                        {job?.title}
                      </JobTitleStyled>
                    </InView>

                    {isMobile &&
                    !chipExist &&
                    job?.stateRegion !== "Singapore" ? (
                      job?.category === "aggregated" ? null : (
                        <ButtonGroup
                          job={job}
                          origin={origin}
                          currentId={currentId}
                          onFollowJobClicked={onFollowJobClicked}
                          unSaveJob={unSaveJob}
                          handleHideJob={handleHideJob}
                        />
                      )
                    ) : null}

                    {!isMobile ? (
                      <BoxContainer gap={"0.625rem"} marginLeft={"-0.3rem"}>
                        <BoxContainer
                          alignItems={"center"}
                          gap={"0.5rem"}
                          width={"44%"}
                        >
                          <DollarIcon />
                          <TextStyled
                            variant={"h4"}
                            color={"rgba(0, 0, 0, 0.60)"}
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"1.25rem"}
                          >
                            {job?.salary === "Undisclosed" ||
                            job?.salary === "Undefined"
                              ? "Undisclosed"
                              : formatSalary(job?.salary, job?.crossBorderData)}
                          </TextStyled>
                        </BoxContainer>
                        <BoxContainer alignItems={"center"} gap={"0.5rem"}>
                          <LocationIcon />
                          <TextStyled
                            variant={"h4"}
                            color={
                              job?.stateRegion === "Singapore"
                                ? Color.reject
                                : "rgba(0, 0, 0, 0.60)"
                            }
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"1.25rem"}
                          >
                            {job?.stateRegion}
                          </TextStyled>
                        </BoxContainer>
                      </BoxContainer>
                    ) : null}
                  </BoxContainer>

                  {/* RIGHT SIDE - COMPANY LOGO AND COMPANY NAME */}
                  <BoxContainer
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"10px"}
                    width={isMobile ? "100%" : "100%"}
                  >
                    <BoxContainer
                      alignItems={isMobile ? "center" : "flex-start"}
                      gap={isMobile ? "0.5rem" : "1rem"}
                      maxWidth={"100%"}
                    >
                      <CustomTooltip
                        followCursor
                        title={"Click to view company profile"}
                      >
                        <CompanyLogoStyled
                          src={companyLogo}
                          alt={company?.name + "logo"}
                          loading={"lazy"}
                          sx={{ objectFit: company?.logo ? "contain" : "fit" }}
                          onClick={(event) =>
                            handleClickLogo(event, company?.slug)
                          }
                        />
                      </CustomTooltip>

                      <CompanyNameStyled variant={"h3"}>
                        {job?.category === "aggregated"
                          ? job?.aggregatedCompanyName
                          : company?.name}
                      </CompanyNameStyled>
                    </BoxContainer>
                    {!isMobile && !chipExist ? (
                      job?.category === "aggregated" ? null : (
                        <ButtonGroup
                          job={job}
                          origin={origin}
                          currentId={currentId}
                          onFollowJobClicked={onFollowJobClicked}
                          unSaveJob={unSaveJob}
                          handleHideJob={handleHideJob}
                        />
                      )
                    ) : null}
                  </BoxContainer>
                </BoxContainer>
                {isMobile ? (
                  <>
                    <MobileChipContainer>
                      <MobileDetailChip type={"jobType"}>
                        {job?.jobType}
                      </MobileDetailChip>
                      <MobileDetailChip type={"state"}>
                        {job?.stateRegion}
                      </MobileDetailChip>
                      <MobileDetailChip type={"salary"}>
                        {job?.salary === "Undisclosed" ||
                        job?.salary === "Undefined"
                          ? "Undisclosed"
                          : formatSalary(job?.salary, job?.crossBorderData)}
                      </MobileDetailChip>
                    </MobileChipContainer>
                    <BoxContainer justifyContent={"flex-end"}>
                      <PostedTextStyled
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {getTime(job) !== null ? getTime(job) : null}
                      </PostedTextStyled>
                    </BoxContainer>
                  </>
                ) : null}
              </TopContainer>

              {isMobile &&
              job?.keywordHighlight &&
              job?.keywordHighlightText?.length > 0 ? (
                <MobileKeywordContainer
                  $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                >
                  {job?.keywordHighlightText?.map((jobPerks, index) => {
                    return (
                      <KeywordChip
                        key={index}
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {jobPerks}
                      </KeywordChip>
                    );
                  })}
                </MobileKeywordContainer>
              ) : null}

              <BottomContainer
                $jobSlotMax={job?.jobSlotType === "job_slot_max"}
              >
                {isMobile ? (
                  <MobileApplyButton
                    disabled={
                      job?.haveApplied || job?.haveApplied === undefined
                    }
                    onClick={(event) => handleApplyJob(event, job, reduxUser)}
                  >
                    {job?.haveApplied || job?.haveApplied === undefined ? (
                      "applied"
                    ) : job?.category === "scraped" ||
                      job?.category === "aggregated" ? ( // Later need to put checking for external job
                      <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                        {"Apply"}
                        <ArrowIconStyled />
                      </BoxContainer>
                    ) : (
                      "apply now"
                    )}
                  </MobileApplyButton>
                ) : (
                  <>
                    <BoxContainer alignItems={"center"} gap={"0.5rem"}>
                      {job?.stateRegion === "Singapore" && (
                        <>
                          <SharedSGTooltip
                            showWhenTap={!isDesktop ? true : false}
                            placement={"bottom-start"}
                          />
                          <DividerStyled
                            orientation="vertical"
                            $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                          />
                        </>
                      )}
                      <JobTypeChip
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {job?.jobType}
                      </JobTypeChip>
                      {job?.keywordHighlight &&
                      job?.keywordHighlightText?.length > 0 ? (
                        <>
                          <DividerStyled
                            orientation="vertical"
                            $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                          />
                          <BoxContainer
                            alignItems={"center"}
                            flexWrap={"wrap"}
                            gap={"0.375rem"}
                          >
                            {job?.keywordHighlightText?.map(
                              (jobPerks, index) => {
                                return (
                                  <KeywordChip
                                    key={index}
                                    $jobSlotMax={
                                      job?.jobSlotType === "job_slot_max"
                                    }
                                  >
                                    {jobPerks}
                                  </KeywordChip>
                                );
                              }
                            )}
                          </BoxContainer>
                        </>
                      ) : null}
                    </BoxContainer>
                    <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                      <PostedTextStyled
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {getTime(job) !== null ? getTime(job) : null}
                      </PostedTextStyled>
                      {origin === "for-you-page" ? (
                        <>
                          <DividerStyled
                            orientation="vertical"
                            $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                          />
                          <HideButton
                            onClick={(event) => handleHideJob(event, job)}
                            $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                          >
                            Hide <HideIcon />
                          </HideButton>
                        </>
                      ) : null}
                    </BoxContainer>
                  </>
                )}
              </BottomContainer>
            </InnerWrapperContainer>
          </CustomTooltip>
          {isDesktop ? (
            <CustomTooltip title={"Click here to apply!"} followCursor>
              <Greenthang
                variant="contained"
                className="apply"
                onClick={handleApplyButtonClick}
                onMouseEnter={() => setHoverApplyButton(true)}
                onMouseLeave={() => setHoverApplyButton(false)}
                disabled={
                  applyingJob
                    ? true
                    : job?.haveApplied || job?.haveApplied === undefined
                    ? true
                    : false
                }
                loading={applyingJob}
              >
                <BoxContainer
                  flexDirection={"column"}
                  alignItems={"center"}
                  gap={"0.325rem"}
                >
                  {/* loading logic should take higher precedence than hover logic */}
                  {job?.haveApplied
                    ? "Applied"
                    : hoverApplyButton
                    ? "Apply Now!"
                    : "Click here to apply!"}
                  {job?.category === "scraped" ||
                  job?.category === "aggregated" ? (
                    <ArrowIconStyled />
                  ) : null}
                </BoxContainer>
              </Greenthang>
            </CustomTooltip>
          ) : null}
        </InnerWrapperContainer>
      </JobCardContainer>
    </LinkContainer>
  ) : null;
}

function HideJobCard(props) {
  const { job, handleUndoHideJob, handleConfirmHideJob } = props;

  const dispatch = useDispatch();

  const [feedback, setFeedback] = useState(null);
  const [feedbackState, setFeedbackState] = useState(false);
  const [confirmFeedback, setConfirmFeedback] = useState(false);

  function handleTriggerFeedback(job) {
    setFeedbackState(true);
  }

  function handleFeedback(event) {
    setFeedback(event.target.value);
  }

  function handleCancelFeedback() {
    setFeedbackState(false);
    setFeedback(null);
  }

  function handleConfirmFeedback(job) {
    const params = {
      jobId: job?.id,
      rejectionReason: feedback,
    };

    dispatch(updateFeedbackHideJob(params)).then((res) => {
      if (res?.type === types.FEEDBACK_HIDE_JOB_SUCCESS) {
        setConfirmFeedback(true);
        setFeedbackState(false);
        setFeedback(null);
      }
    });
  }

  return (
    <JobCardContainer>
      <HideJobContainer>
        {feedbackState ? (
          <BoxContainer flexDirection={"column"} width={"100%"}>
            <BoxContainer
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              margin={"0 0 0.5rem"}
            >
              <TextStyled
                fontSize={"0.875rem"}
                fontWeight={"700"}
                lineHeight={"1.5rem"}
                letterSpacing={"0.1px"}
              >
                Tell Us Why
              </TextStyled>
            </BoxContainer>
            <OptionContainer
              value={feedback}
              onChange={(event) => handleFeedback(event)}
            >
              <OptionLabel
                value="Completely irrelevant to my Profile/Activity"
                control={<RadioStyled />}
                label="Completely irrelevant to my Profile/Activity"
              />
              <OptionLabel
                value="Not the Experience Level I'm looking for"
                control={<RadioStyled />}
                label="Not the Experience Level I'm looking for"
              />
              <OptionLabel
                value="Salary is not what I'm looking for"
                control={<RadioStyled />}
                label="Salary is not what I'm looking for"
              />
              <OptionLabel
                value="Others"
                control={<RadioStyled />}
                label="Others"
              />
            </OptionContainer>
            <BoxContainer
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <TextButton
                onClick={() => handleCancelFeedback()}
                sx={{ p: "0" }}
              >
                <LeftArrowIcon />
              </TextButton>

              <TextButton
                onClick={() => handleConfirmFeedback(job)}
                disabled={!feedback}
              >
                Confirm
              </TextButton>
            </BoxContainer>
          </BoxContainer>
        ) : (
          <>
            <BoxContainer flexDirection={"column"} width={"100%"}>
              <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                {confirmFeedback ? (
                  <TextStyled
                    fontSize={"0.875rem"}
                    fontWeight={"700"}
                    lineHeight={"1.5rem"}
                    letterSpacing={"0.1px"}
                  >
                    Thank you for your feedback
                  </TextStyled>
                ) : (
                  <>
                    <HideIcon />
                    <TextStyled
                      fontSize={"0.875rem"}
                      fontWeight={"700"}
                      lineHeight={"1.5rem"}
                      letterSpacing={"0.1px"}
                    >
                      Job Hidden
                    </TextStyled>
                  </>
                )}
              </BoxContainer>
              <TextStyled fontSize={"0.75rem"} lineHeight={"1.25rem"}>
                {confirmFeedback
                  ? "We will tune your recommendations."
                  : "This job will not be recommended to you again for 6 months."}
              </TextStyled>
            </BoxContainer>
            <BoxContainer
              justifyContent={"flex-end"}
              gap={"0.25rem"}
              width={"100%"}
            >
              {confirmFeedback ? (
                <TextButton
                  onClick={() => handleConfirmHideJob()}
                  $grey
                  sx={{ p: "0" }}
                >
                  <CloseIcon />
                </TextButton>
              ) : (
                <>
                  <TextButton onClick={() => handleTriggerFeedback(job)}>
                    Tell Us Why
                  </TextButton>
                  <TextButton onClick={() => handleUndoHideJob(job)} $grey>
                    Undo
                  </TextButton>
                </>
              )}
            </BoxContainer>
          </>
        )}
      </HideJobContainer>
    </JobCardContainer>
  );
}

function ButtonGroup(props) {
  const {
    job,
    origin,
    currentId,
    onFollowJobClicked,
    unSaveJob,
    handleHideJob,
  } = props;
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width: 640px)");

  const bookmarkingJob = useSelector((state) => state?.jobs?.bookmarkingJob);
  const unbookmarkJob = useSelector((state) => state?.jobs?.unbookmarkJob);

  function onTriggerSaveJob(event, job) {
    event.stopPropagation();
    event.preventDefault();

    if (!checkAuthentication()) {
      return dispatch(updateSelectedJob(job)).then(() => {
        dispatch(updateSignUpOrigin("save_job")).then(() => {
          dispatch(updateShowSignInModalStatus(true));
        });
      });
    }

    if (!job?.bookmark) {
      isSavingJob = true;
      if (onFollowJobClicked) {
        onFollowJobClicked(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    } else {
      isSavingJob = true;
      if (unSaveJob) {
        unSaveJob(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    }
  }

  return (
    <BoxContainer
      justifyContent={"flex-end"}
      alignItems={"center"}
      gap={"0.625rem"}
    >
      <BookmarkButton onClick={(event) => onTriggerSaveJob(event, job)}>
        {job?.bookmark ? (
          unbookmarkJob && currentId === job?.id ? (
            <CircularProgressStyled />
          ) : (
            <SaveFilledIcon />
          )
        ) : bookmarkingJob && currentId === job?.id ? (
          <CircularProgressStyled />
        ) : (
          <SaveOutlineIcon />
        )}
      </BookmarkButton>

      {isMobile && origin === "for-you-page" ? (
        <HideButton onClick={(event) => handleHideJob(event, job)}>
          <HideIcon />
        </HideButton>
      ) : null}
    </BoxContainer>
  );
}

export default AccordionJobCard;
