import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled, { css } from "styled-components";
import withTheme from "@mui/styles/withTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled as styling } from "@mui/material/styles";

// Grid Components //
export const ContainerStyled = styled(Grid)`
  && {
    background-color: ${Color.white};
    width: 100%;
    color: ${Color.black};
    padding: 15px 60px 32px 60px;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
  }
`;

export const BlankContainer = styling(Grid)({
  padding: "8px 60px",
  position: "fixed",
  bottom: 0,
  backgroundColor: "white",
  overflow: "hidden",
  zIndex: 800,
  width: "100%",
  borderTop: `1.5px solid ${Color.lightGrey}`,
  maxHeight: "60px",
});

export const CopyRightContainer = styling(Grid)((props) => ({
  display: "flex",
  alignItems: "center",

  [props.theme.breakpoints.down("md")]: {
    width: "auto",
  },
}));

export const CollapseFooterContainer = styling(Grid)((props) => ({
  display: "flex",
  justifyContent: "space-between",

  [props.theme.breakpoints.down("tablet")]: {
    display: "block",
  },

  [props.theme.breakpoints.down("mobileL")]: {
    flexWrap: "wrap",
  },
}));

export const MoreButtonContainer = styling(Grid)({
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const CloseButtonContainer = styling(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  width: "auto",
  borderRadius: "20px 20px 0px 0px",
});

export const GridLinks = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
`;

export const GridLinksContainer = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const GridIcons = styled(Grid)`
  && {
    display: flex;
    flex-wrap: no-wrap;
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }
`;

export const GridMission = withTheme(styled(Grid)`
  && {
    width: 100%;
  }
`);

export const GridIconsContainer = withTheme(styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.down("mobileL")} {
      margin: 0 0 10px 0;
    }

    ${(props) => props.theme.breakpoints.between("mobileL", "tablet")} {
      width: 40%;
    }
  }
`);

export const GridLinkedin = withTheme(styled(Grid)`
  && {
    margin: 30px 0;
  }
`);

export const ContentContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: flex;
      flex-wrap: wrap;
    }
  }
`);

export const ColumnContainer = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.section === "download_app_logo" &&
      css`
        display: grid;
      `}

    ${(props) => props.theme.breakpoints.down("tablet")} {
      ${(props) => props.section === "mission" && css``}

      ${(props) =>
        props.section === "download_app_logo" &&
        css`
          width: 100%;
          margin: 0 0 20px;
        `}
    }
  }
`);

export const ColumnContainerMiddle = withTheme(styled(Grid)`
  && {
    display: grid;
    grid-template-columns: 20% repeat(2, 30%);
    gap: 20px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: block;
      margin: 20px 0;
    }
  }
`);

export const CopyrightContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: block;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      flex-wrap: wrap;
    }
  }
`);

export const CopyrightContainerMiddle = withTheme(styled(Grid)`
  && {
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin: 20px 0 0;
    }
  }
`);

export const FirstContentContainer = withTheme(styled.div`
  && {
    display: grid;
    grid-template-columns: 40% auto;
    width: 90%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: flex;
      flex-wrap: wrap;
    }
  }
`);

export const VerticalSeparator = withTheme(styled.div`
  && {
    width: 1px;
    height: 15px;
    background-color: black;
    margin: 0 12px;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      height: 35px;
    }
  }
`);

export const ColumnLink = withTheme(styled(Grid)`
  && {
    margin: 0;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin: 0 0 20px 0;
    }
  }
`);

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    text-transform: capitalize;
    font-weight: ${FontSize.bold};
    font-size: ${FontSize.desktopHeadline2};
    margin-bottom: 20px;
  }
`;

export const CompanyTitleStyled = styled(Typography)`
  && {
    text-transform: capitalize;
    font-weight: ${FontSize.bold};
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
`;

export const CopyrightText = withTheme(styled(Typography)`
  && {
    font-size: 0.8rem;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin: 20px 0 0;
    }
  }
`);

export const MissionText = styled(Typography)`
  && {
    font-size: 0.875rem;
    text-align: left;
    width: 85%;
  }
`;

export const SpanStyled = styled.span`
  text-transform: lowercase;
`;

export const LinkText = styled.span`
  && {
    font-size: 0.875rem;
    text-transform: capitalize;
    color: ${Color.black};
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 15px;

    &:hover {
      text-decoration: underline;
      border-color: ${Color.black};
    }
  }
`;

export const LinkTextNoMargin = styled.span`
  && {
    font-size: 0.875rem;
    text-transform: capitalize;
    color: ${Color.black};
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      border-color: ${Color.black};
    }
  }
`;

// Link Components //
export const LinkIcon = styled(Link)`
  && {
    color: ${Color.black};
  }
`;

export const LinkStyled = styled(Link)`
  && {
    width: 150px;
  }
`;

export const LinkExternal = styled(Link)`
  && {
    margin-bottom: 15px;
    text-decoration: none;
  }
`;

// Icon Components //
export const IconStyled = withTheme(styled(FontAwesomeIcon)`
  && {
    margin-right: 20px;
    width: auto;
    height: 20px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      cursor: pointer;
      height: 25px;
      width: auto;
    }

    &:hover {
      color: ${Color.hiredlyPurple};
    }
  }
`);

export const CloseIconStyled = styling(CloseIcon)({
  //
});

export const ArrowIconStyled = styling(KeyboardArrowUpIcon)({
  //
});

// Image Components //
export const DownloadAppLogo = withTheme(styled.img`
  && {
    max-width: 100%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      max-width: 100%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      max-width: 100%;
    }
  }
`);

export const QrCode = withTheme(styled.img`
  && {
    width: 70%;
    height: auto;
    margin: auto;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: none;
    }
  }
`);

export const LinkedinLogo = withTheme(styled.img`
  && {
    width: 30%;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 40%;
    }
  }
`);

// Button //
export const CloseButton = styling(Button)({
  backgroundColor: "white",
  color: "black",
  width: "auto",
  borderRadius: "10px 10px 0px 0px",
  textTransform: "none",
  margin: "0px 10px 0px 5px",

  "&:hover": {
    backgroundColor: "white",
  },
});

export const MoreButton = styling(Button)({
  textTransform: "none",

  "&:hover": {
    backgroundColor: "white",
  },
});

// Divider //
export const DividerStyled = styling(Divider)({
  color: Color.lightGrey,
});

// Drawer //
export const DrawerStyled = styling(Drawer)({
  "& .MuiDrawer-paper": {
    borderRadius: "20px 20px 0px 0px",
  },
});
